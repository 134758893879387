import './App.css';

function App() {
  return (
    <div className="App-Container">
      <div className="App-Context-Bar">
        <select className="App-Selector" name="App" id="App">
          <option value="1">1</option>
          
          <option value="2">2</option>
          
          <option value="3">3</option>
        </select>
      </div>
      <div className='App-Area'>

      </div>
    </div>
  );
}

export default App;
